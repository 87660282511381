export const getFingerprint = () => {
  const fingerprintData = {
    ip: window.CLIENT_IP?.split(',')[0] || '',
    language: navigator.language,
    platform: navigator.platform || 'unknown',
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
  };
  return btoa(JSON.stringify(fingerprintData));
};
