<template>
  <div class="filter-container">
    <button class="filter" type="button" @click.stop="open = !open">
      Filter by
      <b v-if="selected" :style="{ color: selected.color }">{{ selected.label }}</b>
      <i class="ic small ic-filter"></i>
    </button>
    <div class="filter-options" v-if="open">
      <button
        v-for="option in options"
        class="filter-option"
        type="button"
        :key="option.value"
        :style="{ color: option.color }"
        @click.stop="$emit('input', option.value); open = false"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSelector',
  props: {
    value: String,
  },
  computed: {
    selected() {
      return this.options.find((option) => option.value === this.value) || null;
    },
  },
  data() {
    return {
      open: false,
      options: [
        { label: 'none', value: 'none', color: 'rgba(255, 255, 255, 0.69)' },
        { label: 'open', value: 'active', color: '#FFEE00' },
        { label: 'closed', value: 'inactive', color: '#F27272' },
      ],
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    window.addEventListener('click', this.close);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close);
  },
};
</script>

<style scoped>
.filter-container {
  position: relative;
  display: inline-block;
}

.filter {
  color: rgba(255, 255, 255, 0.69);
}

.filter b {
  margin: 0 6px;
}

.filter i {
  opacity: 0.69;
}

.filter-options {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  border-radius: 16px;
  background: #1F1D2C;
  z-index: 10;
  padding: 8px 0;
  overflow: hidden;
}

.filter-option {
  display: block;
  width: 100%;
  padding: 8px 24px;
  font-weight: bold;
}

.filter-option:hover {
  background: #2C2B3B;
}
</style>
